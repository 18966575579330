import { createContext, useContext, useState } from 'react'

const Context = createContext()

export function SaleStateProvider({ children }) {
  const [isPublicSale, setIsPublicSale] = useState(false)
  return (
    <Context.Provider value={[isPublicSale, setIsPublicSale]}>
      {children}
    </Context.Provider>
  )
}

export function useSaleContext() {
  return useContext(Context)
}
